<template>
  <div v-if="phase === 1">
    <div class="header">
      <button
        @click="backToMenu"
        class="btn-img">
        <img src="@/assets/icon-back.png"/>
      </button>
    </div>
    <select-trial-exam
      label="Next"
      @submit="getStudentList"/>    
  </div>
  <div v-else-if="phase === 2">
    <div class="header">
      <button
        @click="backToSelectLesson"
        class="btn-img">
        <img src="@/assets/icon-back.png"/>
      </button>
    </div>
    <div class="page-title">
      Results
    </div>
    <div class="search">
      <div>
        <select v-model="selectedClassId">
          <option
            :key="cls.id"
            :value="cls.id"
            v-for="cls in allClasses">
            {{cls.name}}
          </option>
        </select>
      </div>
      <div class="keyword">
        <img
          src="@/assets/icon-search.svg"/>
        <input type="text" autocomplete="off" v-model="keyword" placeholder="Enter the student's ID or name"/>
      </div>
      <button
        @click="searchResults"
        class="btn-bg ml-2">Search</button>      
    </div>
    <div class="results">
      <div v-if="results.length === 0" class="font-24 mt-1">
        No result
      </div>
      <table v-else>
        <tr
          v-for="(result, i) in results"
          :class="{selected: isSelected(result)}"          
          @click="selectTest(result)"
          :key="i">
          <td class="underline">{{result.testDate}}</td>
          <td class="underline">{{result.email}}</td>
          <td class="underline">{{result.grade}}</td>
          <td class="underline">{{result.name}}</td>
          <td>{{result.score}}</td>
        </tr>
      </table>
    </div>
    <div class="mt-2">
      <button
        class="btn-bg"
        :disabled="!displayResult"        
        @click="showResult">
        Display
      </button>
    </div>
  </div>
</template>

<script>
import SelectTrialExam from '../../components/molecule/SelectTrialExam.vue'
import allClasses from '../../mixins'

export default {
  name: 'TrialResults',
  components: { SelectTrialExam, },
  mixins: [ allClasses ],
  data() {
    return {
      phase: 1,
      selectedLesson: {},
      results: [],
      selectedClassId: null,
      selectedTest: null,
      keyword: null,
    }
  },
  computed: {
    displayResult() {
      return this.selectedTest != null && this.selectedTest.testDate.length > 3
    },
    selectedClass() {
      if (this.selectedClassId) {
        return this.allClasses.find(c => c.id === this.selectedClassId)
      } else {
        return null
      }
    },
    isSelected() {
      return result => result.testHistoryId === this.selectedTest?.testHistoryId
    }    
  },
  methods: {
    backToMenu() {
      this.$router.back()
    },    
    getStudentList(lesson) {
      this.selectedLesson = lesson
      this.phase++
      this.selectedClassId = this.allClasses[0].id
      this.searchResults()
    },
    backToSelectLesson() {
      this.results = []      
      this.phase--
      this.selectedTest = null
    },
    searchResults() {
      this.$api.post('/students', {
        testId: this.selectedLesson.id,
        class: this.selectedClass.class,
        grade: this.selectedClass.grade,
        keyword: this.keyword,
      }).then(res => {
        this.results = res.data
      })
    },
    selectTest(test) {
      this.selectedTest = test
    },
    showResult() {
      const routeData = this.$router.resolve(`/result/trialTest/${this.selectedTest.testHistoryId}?mode=view`)
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.lessons {
  margin-top: 50px;
  .lesson-row {
    display: flex;
    .lesson {
      flex: 1;
      background: white;
      border-radius: 50px;
      margin: 40px 10px;
      padding: 30px 5px;
      cursor: pointer;
      border: 1px solid gray;
    }
  }
}
.search {
  margin: auto;
  margin-top: 5px;
  width: 80%;
  display: flex;
  align-items: center;
  select {
    padding: 13px 15px;
  }
  .keyword {
    display: flex;
    align-items: center;
    width: 390px;
    margin-left: 30px;
    img {
      z-index: 999;
      margin-left: 10px;
      width: 30px;
      height: 30px;
    }
    input {
      position: absolute;
      font-size: 20px;
      border: none;
      padding: 13px 15px;
      border-radius: 5px;
      width: 320px;
      padding-left: 45px;
    }
  }  
}
.results {
  margin: auto;
  margin-top: 20px;
  width: 80%;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
  background: white;
  border-radius: 5px;
  padding: 5px 0px;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);

  table {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border-collapse: collapse;
    td {
      border-bottom: 1px solid rgb(192, 186, 186);
      line-height: 250%;
    }
    .selected, tr:hover {
      background: rgb(243, 233, 233);
      cursor: pointer;
    }
    .underline {
      text-decoration: underline;
    }
  }
}
</style>

